import React, { FC } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { StyledModal } from "./index-modal.style";
import MailChimpForm from "./mailchimp/mailchimp";
import Icon from "./category-icon";
import { Link } from "gatsby";

interface IndexModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bg: any;
  image: boolean;
}

const IndexModal: FC<IndexModalProps> = ({ open, setOpen, bg, image }) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    localStorage.setItem("seen-two", "true");
    setOpen(!open);
  };
  const handleClickLink = (e: any) => {
    localStorage.setItem("seen-two", "true");
  };

  if (open && image) {
    return (
      <StyledModal open={open}>
        <div className="newsletter image">
          <button className="button image" type="button" onClick={handleClick}>
            <Icon name="close" />
          </button>
          <Link to="/plan-your-visit" onClick={handleClickLink}>
            <div>
              <GatsbyImage image={bg} alt="" />
            </div>
          </Link>
        </div>
      </StyledModal>
    );
  }
  if (open && !image) {
    return (
      <StyledModal open={open}>
        <div className="newsletter">
          <button
            className="button"
            type="button"
            onClick={() => setOpen(!open)}
          >
            <Icon name="close" />
          </button>
          <MailChimpForm modal setOpen={setOpen} />
          <div className="background">
            <GatsbyImage image={bg} alt="" />
          </div>
        </div>
      </StyledModal>
    );
  } else return null;
};
export default IndexModal;
