import { FC } from "react";
import { Box } from "theme-ui";
import Img from "gatsby-image";
import styles from "./image-banner.style";

interface Props {
  text?: any;
  image: any;
  link?: string;
}

export const ImageBanner: FC<Props> = ({ text, image }) => {
  return (
    <Box as="section" sx={styles.banner}>
      <Img fluid={image?.fluid} alt={image?.alt} />
      {/* {text && (
        <Box sx={styles.overlayText}>
          <div>{text.raw[0].text}</div>
        </Box>
      )} */}
    </Box>
  );
};
