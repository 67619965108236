import { FC } from "react";
import { Box, Text } from "theme-ui";
import styles from "./image-button.style";
import { Link } from "gatsby";
import Img from "gatsby-image";
import cx from "classnames";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "../../utils/linkResolver";

interface ImageButtonProps {
  title?: any;
  text?: any;
  image: any;
  link: any;
  cta?: any;
  position?: boolean;
  hasButton?: boolean;
  color?: string;
  bigImage?: boolean;
  titleImage?: any;
  linkImage?: any;
}

export const ImageButton: FC<ImageButtonProps> = ({
  title,
  text,
  image,
  link,
  cta,
  position,
  hasButton,
  color,
  bigImage,
  titleImage,
  linkImage,
}) => {
  const description = RichText.render(text.raw, linkResolver);
  if (bigImage)
    return (
      <div
        style={{
          backgroundColor: color ? color : "white",
        }}
      >
        {position && (
          <Box as="section" sx={styles.wrapperBigImage}>
            <Box sx={styles.imageWrap}>
              <Img fluid={image?.fluid} alt={image?.alt} />
            </Box>
            <Box sx={styles.content}>
              {title && title.raw.length > 0 && (
                <div className="title">
                  <Text
                    as="h3"
                    className={cx({
                      white: color,
                    })}
                  >
                    {title.raw[0].text}
                  </Text>
                  {text && (
                    <div
                      className={cx("description-button", {
                        marginLeft: true,
                      })}
                    >
                      {description}{" "}
                    </div>
                  )}
                </div>
              )}
              {hasButton && (
                <Link
                  to={link!.uid}
                  className={cx("button-link-diff", {
                    marginLeft: true,
                  })}
                >
                  <div>{cta.raw[0].text}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#054752"
                    className="svg-button"
                  >
                    <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                  </svg>
                </Link>
              )}
            </Box>
          </Box>
        )}
        {!position && (
          <Box as="section" sx={styles.wrapperBigImage}>
            <Box sx={styles.content}>
              {title && title.raw.length > 0 && (
                <div className="title">
                  <Text
                    as="h3"
                    className={cx({
                      left: !position,
                      white: color !== "#fefaf3",
                      cgTeal: color === "#fefaf3",
                    })}
                  >
                    {title.raw[0].text}
                  </Text>
                  {text && (
                    <div
                      className={cx("description-button", {
                        left: !position,
                        white: color !== "#fefaf3",
                        cgTeal: color === "#fefaf3",
                        margin: true,
                      })}
                    >
                      {description}
                    </div>
                  )}
                </div>
              )}
              {hasButton && (
                <Link
                  to={link!.uid}
                  className={cx("button-link-diff", {
                    left: !position,
                    white: color !== "#fefaf3",
                    cgTeal: color === "#fefaf3",
                  })}
                >
                  <div>{cta.raw[0].text}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill={color !== "#fefaf3" ? "white" : "#054752"}
                    className="svg-button"
                  >
                    <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                  </svg>
                </Link>
              )}
            </Box>
            <Box
              sx={styles.imageWrap}
              className={cx({
                hasLink: titleImage.raw.length > 0,
              })}
            >
              <Img fluid={image?.fluid} alt={image?.alt} />
              {titleImage && titleImage.raw.length > 0 && (
                <Box sx={styles.contentRow}>
                  {titleImage && titleImage.raw.length > 0 && (
                    <div className="title-row">
                      <Text as="h3">{titleImage.raw[0].text}</Text>
                    </div>
                  )}
                  {linkImage.uid && (
                    <Link to={`/${linkImage.uid}`} className="button-link-diff">
                      <div>Learn more</div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="white"
                      >
                        <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                      </svg>
                    </Link>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </div>
    );

  return (
    <Box
      as="section"
      sx={{
        ...styles.wrapper,
        backgroundImage: `url(${image?.fluid?.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {position && (
        <Box sx={styles.overlayContent}>
          {hasButton && (
            <Link to={link!.uid} className="button-link">
              <div>{cta.raw[0].text}</div>
            </Link>
          )}
          {text && (
            <div
              className="description"
              style={{ color: color ? color : "white" }}
            >
              <div>{description}</div>
            </div>
          )}
        </Box>
      )}
      {!position && (
        <Box sx={styles.overlayContent}>
          {text && (
            <div
              className="description"
              style={{ color: color ? color : "white" }}
            >
              <div>{description}</div>
            </div>
          )}
          {hasButton && (
            <Link to={link!.uid} className="button-link">
              <div>{cta.raw[0].text}</div>
            </Link>
          )}
        </Box>
      )}
    </Box>
  );
};
