const styles = {
  wrapper: {
    margin: "0 auto",
    padding: "60px 80px",
    "@media only screen and (max-width: 590px)": {
      padding: "0 20px",
    },
    "&.background": {
      backgroundColor: "#FEFAF3",
    },
    ".header-title": {
      display: "flex",
      "justify-content": "space-between",
      "flex-direction": "column",
      "align-items": "flex-start",
      margin: "20px auto",
      width: "100%",
      "@media only screen and (max-width: 890px)": {
        margin: "30px auto 70px",
      },
      "@media only screen and (max-width: 590px)": {
        padding: "0",
        margin: "20px auto 40px",
      },
      h2: {
        fontSize: ["36px", "46px", "56px"],
        fontWeight: 500,
        lineHeight: "1.15em",
        paddingBottom: ".54em",
        color: "cgTeal",
      },
      ".line": {
        width: "10%",
        height: "3px",
        backgroundColor: "cgTeal",
        "@media only screen and (max-width: 890px)": {
          display: "none",
        },
      },
    },
  },
  wrapperImages: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    width: "100%",
    /* backgroundColor: "#f5f5f5", */
    "@media only screen and (max-width: 590px)": {
      margin: "40px auto",
    },
    ".content-images": {
      display: "flex",
      "flex-direction": "row",
      width: "100%",
      gap: "40px",
      "@media only screen and (max-width: 590px)": {
        "flex-direction": "column",
      },
    },
    ".image-row": {
      display: "flex",
      "flex-direction": "column",
      width: "100%",
      margin: "0 auto",
      "@media only screen and (max-width: 890px)": {
        width: "70%",
      },
      "@media only screen and (max-width: 590px)": {
        width: "100%",
      },
      h3: {
        display: "flex",
        alignItems: "flex-start",
        fontSize: ["20px", "22px", "24px"],
        fontWeight: 500,
        lineHeight: "1.15em",
        paddingTop: "0.8em",
        paddingBottom: ".54em",
        "text-align": "left",
        color: "cgTeal",
      },
      p: {
        fontSize: ["14px", "16px", "18px"],
        fontWeight: 400,
        lineHeight: "1.15em",
        "text-align": "left",
        color: "cgTeal",
        paddingBottom: "0.8em",
      },
      a: {
        color: "white",
        textDecoration: "none",
      },
      ".cta-button": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "flex-direction": "row",
        gap: "10px",
        color: "cgTeal",
        cursor: "pointer",
        transition: "all 0.2s ease",
        fontSize: ["14px", "15px", "16px"],
        fontWeight: 600,
        lineHeight: "22px",
        letterSpacing: "0.8px",
        textDecoration: "none",
        "text-align": "center",
        "text-transform": "uppercase",
        p: {
          color: "black !important",
          "&:hover": {
            color: "white !important",
          },
        },
      },
    },
  },
  imagePreview: {
    width: "100%",
    overflow: "hidden",
    ".gatsby-image-wrapper": {
      height: ["220px", "320px", "440px", "440px", "500px"],
      width: "100%",
    },
    img: {
      width: "100%",
      overflow: "hidden",
      "object-fit": "cover",
    },
    a: {
      color: "white",
      textDecoration: "none",
    },
    "@media only screen and (max-width: 1200px)": {
      width: "100%",
      height: "300px",
    },
    "@media only screen and (max-width: 890px)": {
      width: "100%",
      height: "250px",
      overflow: "hidden",
    },
    "@media only screen and (max-width: 590px)": {
      width: "100%",
      height: "200px",
      overflow: "hidden",
    },
  },
};

export default styles;
