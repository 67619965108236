const styles = {
  banner: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "auto",
    /* maxWidth: "1650px", */
    borderRadius: 0,
    position: "relative" as "relative",
    overflow: "hidden",
    height: ["400px", "670px", "670px", "670px", "670px"],
    "@media only screen and (min-width: 891px)": {
      paddingTop: 0,
    },
    "@media only screen and (max-width: 1200px)": {
      
    },
    ".gatsby-image-wrapper": {
      width: "100%",
      height: ["400px", "670px", "670px", "670px", "670px"],
      position: "relative" as "relative",
    },
  },
  overlayText: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "text-align": "center",
    color: "white",
    zIndex: "20",
    transition: "all 0.4s ease",
    fontSize: "56px",
    fontWeight: "bold",
    width: "80%",
    "@media only screen and (min-width: 1200px)": {
      maxWidth: "1000px",
      fontSize: "48px",
    },
    "@media only screen and (max-width: 1023px)": {
      width: "95%",
      fontSize: "38px",
    },
    "@media only screen and (max-width: 500px)": {
      width: "95%",
      fontSize: "22px",
    },
  },
};

export default styles;
