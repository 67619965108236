import { FC } from "react";
import { Box, Text } from "theme-ui";
import styles from "./new-section.styles";
import { Link } from "gatsby";
import Img from "gatsby-image";
import cx from "classnames";

interface NewsSectionProps {
  news: any;
  title?: string;
  hasBackground?: boolean;
}

export const NewsSection: FC<NewsSectionProps> = ({
  news,
  title,
  hasBackground,
}) => {
  
  return (
    <Box
      sx={styles.wrapper}
      className={cx({
        background: hasBackground,
      })}
    >
      {title && news.length > 0 && (
        <div className="header-title">
          <Text as="h2">{title}</Text>
          {/* <div className="line"></div> */}
        </div>
      )}
      <Box sx={styles.wrapperImages}>
        <div className="content-images">
          {news.map((item: any, index: number) => {
            return (
              <div className="image-row" key={`${item.image?.alt}-${index}`}>
                <Box sx={styles.imagePreview}>
                  <Link
                    to={
                      item.link_new_images.link_type === "Document"
                        ? `/${item.link_new_images.uid}`
                        : item.link_new_images.url
                    }
                  >
                    <Img fluid={item?.image.fluid} alt={item.image?.alt} />
                  </Link>
                </Box>
                {item.title_news && (
                  <Text as="h3">{item.title_news.raw[0].text}</Text>
                )}
                {item.subtitle &&
                  item.subtitle.raw.length > 0 &&
                  item.subtitle.raw[0].text.length > 0 && (
                    <Text as="p">{item.subtitle.raw[0].text}</Text>
                  )}
                <Link
                  to={
                    item.link_new_images.link_type === "Document"
                      ? `/${item.link_new_images.uid}`
                      : item.link_new_images.url
                  }
                  className="cta-button"
                >
                  <div>{item.cta ? item.cta.raw[0].text : "Read More"}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#054752"
                  >
                    <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                  </svg>
                </Link>
              </div>
            );
          })}
        </div>
      </Box>
    </Box>
  );
};
