const styles = {
  container: {
    padding: "0 80px",
  },
  wrapper: {
    margin: "0 auto",
    a: {
      color: "#535F61",
      /* ":hover, :focus": {
        color: "#D79A2B",
      }, */
    },
  },
  contentText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    padding: "20px 0 30px 0",
    "text-align": "center",
    width: "100%",
    backgroundColor: "#FEFAF3",
  },
  summaryHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "flex-direction": "column",
    gap: "25px",
    padding: "40px 200px",
    "@media only screen and (max-width: 590px)": {
      width: "85%",
      padding: "10px 20px",
    },
    p: {
      fontSize: ["18px", "22px", "24px"],
      fontWeight: 500,
      color: "cgTeal",
      margin: "0 auto",
      letterSpacing: "0.5px",
      lineHeight: "1.5em",
    },
    h3: {
      fontSize: ["18px", "22px", "24px"],
      fontWeight: 500,
      color: "cgTeal",
      margin: "0 auto",
      letterSpacing: "0.5px",
      lineHeight: "1.35em",
    },
  },
  wrapperBackground: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    padding: "40px 0",
    "text-align": "center",
    width: "100%",
    gap: "40px",
    "@media only screen and (max-width: 890px)": {
      width: "95%",
    },
    "@media only screen and (max-width: 590px)": {
      width: "85%",
    },
    p: {
      fontSize: ["16px", "20px", "22px"],
      fontWeight: 500,
      color: "cgTeal",
      margin: "0 auto",
      letterSpacing: "0.5px",
    },
  },
  articleWrap: {
    paddingTop: "20px",
    "@media only screen and (max-width: 890px)": {
      "flex-direction": "column",
    },
    "@media only screen and (max-width: 590px)": {
      paddingTop: 0,
    },
  },
  shopWrap: {
    padding: "20px 0",
    margin: "40px auto",
    "@media only screen and (max-width: 890px)": {
      "flex-direction": "column",
      padding: "10px 10px 20px",
    },
    "@media only screen and (max-width: 590px)": {
      padding: "10px 0",
      margin: "10px auto",
    },
  },
  communityWrap: {
    margin: "0 auto 60px",
    width: "60%",
    maxWidth: "675px",
    display: "grid",
    gridColumnGap: "1rem",
    gridRowGap: "1.5rem",
    gridTemplateColumns: "repeat( auto-fit, minmax(120px, 1fr) )",
    "@media only screen and (max-width: 1200px)": {
      width: "85%",
    },
    "@media only screen and (max-width: 890px)": {
      width: "95%",
      justifyContent: "space-evenly",
    },
    "@media only screen and (max-width: 400px)": {
      width: "80%",
      justifyContent: "space-between",
    },
  },
  space: {
    height: "60px",
    "@media only screen and (max-width: 890px)": {
      height: "30px",
    },
  },
};

export default styles;
