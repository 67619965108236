/** @jsx jsx */
import React, { Fragment } from "react";
import { Box, jsx } from "theme-ui";
import ProductCardWithLink from "../product-card/with-link";
import styles from "./product-slider.style";
import Carousel from "../carousel/carousel";
/* import { QueryData } from "./types"; */
import { Product } from "components/product-buy/types";
import { HasAvailableVariant } from "../../utils";

type Props = {
  data: any;
  scrollTo?: string;
  scrollOffset?: number;
  fromShop?: boolean;
};

const ProductSlider: React.FC<Props> = ({ data, fromShop }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 100000000, min: 1401 },
      items: 5,
    },
    ipad: {
      breakpoint: { max: 1400, min: 1061 },
      items: 5,
    },
    smipad: {
      breakpoint: { max: 1060, min: 651 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1,
      partialVisibilityGutter: 50,
    },
  };
  const getPrice = (price: any) =>
    Intl.NumberFormat(undefined, {
      currency: "USD",
      minimumFractionDigits: 2,
      /* style: "currency", */
    }).format(parseFloat(price || 0));
  return (
    <Box className="picksyProductSlider" sx={styles.wrapper}>
      <Carousel visible={true} ssr={true} responsive={responsive} gutter="0">
        {data.map((productNode: any) => {
          const productItem = fromShop ? productNode : productNode.node;
          const {
            id,
            handle,
            shopifyId,
            // parentId,
            images: [firstImage],
            variants: [firstVariant],
          } = productItem;

          const isAvailable = HasAvailableVariant(productItem);

          const product: Product = {
            ...productItem,
            isVariant: productItem.variants.length > 0,
            options: [],
            sku: "",
            parentId: "",
            subtitle: "",
            price: productItem.variants[0]?.price,
            image: productItem.images[0],
            available: isAvailable,
          };

          if (isAvailable) {
            return (
              <Fragment key={id}>
                <ProductCardWithLink
                  size="large"
                  path={`/product/${handle}`}
                  // availableForSale={availableForSale}
                  price={getPrice(firstVariant?.price)}
                  id={id}
                  shopPage={true}
                  product={product}
                />
              </Fragment>
            );
          }
        })}
      </Carousel>
    </Box>
  );
};

export default ProductSlider;
