const styles = {
  wrapper: {
    width: "100%",
    margin: "0 auto 40px",
    padding: "20px 0",
    "@media only screen and (max-width: 590px)": {
      margin: "0 auto 40px",
    },
    ".header-title": {
      display: "flex",
      "justify-content": "space-between",
      "flex-direction": "row",
      "align-items": "flex-start",
      margin: "20px auto",
      padding: "20px 0",
      width: "100%",
      "@media only screen and (max-width: 890px)": {
        margin: "10px auto",
      },
      "@media only screen and (max-width: 590px)": {
        padding: "20px 10px",
        "flex-direction": "column",
        "align-items": "center",
        width: "80%",
      },
      h2: {
        padding: "20px 80px",
        fontSize: ["36px", "46px", "56px"],
        fontWeight: 500,
        lineHeight: "1.15em",
        paddingBottom: ".54em",
        color: "cgTeal",
        "@media only screen and (max-width: 590px)": {
          padding: "10px 10px",
        },
      },
      ".line": {
        width: "30%",
        height: "3px",
        margin: "0 80px",
        backgroundColor: "cgTeal",
        "@media only screen and (max-width: 890px)": {
          display: "none",
        },
      },
      ".content-summary": {
        padding: "20px 80px",
        display: "flex",
        "justify-content": "center",
        width: "50%",
        "@media only screen and (max-width: 590px)": {
          padding: "0 10px",
          margin: "0 auto",
          width: "100%",
        },
        p: {
          fontSize: ["16px", "18px", "20px"],
          fontWeight: 500,
          color: "cgTeal",
          margin: "0 auto",
          letterSpacing: "0.8px",
          lineHeight: "1.5em",
          "text-align": "left",
          "@media only screen and (max-width: 590px)": {
            "text-align": "center",
          },
        },
      },
    },
  },
  container: {
    padding: "0 80px",
    "@media only screen and (max-width: 590px)": {
      padding: "0 20px",
    },
  },
};
export default styles;
