const styles = {
  buttonStyle: {
    backgroundColor: "#AE1485",
    width: "180px",
    padding: ["12px 22px", "14px 26px", "16px 30px"],
    borderRadius: "8px",
    "text-align": "center",
    position: "sticky" as "sticky",
    bottom: "20px",
    left: "22%",
    zIndex: "100",
    boxShadow: "1px 1px 6px #555",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen and (min-width: 500px)": {
      transform: "translateX(-50%)",
      left: "50%",
      width: "240px",
    },
    a: {
      textDecoration: "none",
    },
  },
  container: {
    padding: ["12px 22px", "14px 26px", "15px 30px"],
    borderRadius: "8px",
    "text-align": "center",
    zIndex: "100",
    cursor: "pointer",
    a: {
      textDecoration: "none",
    },
    "&.button-bg-violet": {
      backgroundColor: "#AE1485",
      color: "white",
      "&:hover": {
        backgroundColor: "#AE1485",
      },
    },
    "&.button-bg-not": {
      backgroundColor: "transparent",
    },
  },
  divButton: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    "flex-direction": "row",
  },
  innerButtonStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    width: "100%",
    color: "white",
    fontSize: ["15px", "15px", "15px"],
    fontWeight: "500",
    "text-transform": "uppercase",
    "text-align": "center",
    lineHeight: "1.15em",
    letterSpacing: "0.8px",
  },
};

export default styles;
