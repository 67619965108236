import React, { FC } from "react";
import { StaticQuery, graphql } from "gatsby";
import ProductSlider from "../product-slider/product-slider";
import { Box, Text } from "theme-ui";
import styles from "./trending-products-home.styles";

const trendingStaticQuery = graphql`
  query {
    allShopifyProduct(filter: { tags: { eq: "Trending" } }, limit: 10) {
      edges {
        node {
          id
          title
          handle
          createdAt
          shopifyId
          variants {
            id
            price
            title
            sku
            selectedOptions {
              name
              value
            }
            image {
              id
              originalSrc
              gatsbyImageData(width: 325, layout: CONSTRAINED)
            }
            price
            shopifyId
            availableForSale
          }
          images {
            id
            originalSrc
            gatsbyImageData(width: 325, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

interface TrendingProductsHomeProps {
  slider: boolean;
  withLink: boolean;
  title?: string;
  summary?: string;
}

const TrendingProductsHome: FC<TrendingProductsHomeProps> = ({
  slider,
  withLink,
  title,
  summary,
}) => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${trendingStaticQuery}`}
    render={({ allShopifyProduct }) => {
      const products = allShopifyProduct?.edges;
      return (
        <Box sx={styles.wrapper}>
          <div className="header-title">
            <Box>
              {title && <Text as="h2">{title}</Text>}
              <div className="line"></div>
            </Box>
            <div className="content-summary">
              <Text as="p">{summary}</Text>
            </div>
          </div>
          <Box sx={styles.container}>
            <ProductSlider data={products} />
          </Box>
        </Box>
      );
    }}
  />
);

export default TrendingProductsHome;
