const styles = {
  container: {
    /* backgroundColor: "#f5f5f5", */
  },
  wrapper: {
    position: "relative" as "relative",
    margin: "40px auto",
    overflow: "hidden",
    padding: "10px 20px",
    width: "100%",
    height: "400px",
    "@media only screen and (min-width: 449px)": {
      paddingTop: "43px",
    },
    "@media only screen and (min-width: 557px)": {
      paddingTop: "50px",
    },
    "@media only screen and (min-width: 891px)": {
      paddingTop: 0,
    },
  },
  wrapperBigImage: {
    display: "flex",
    "flex-direction": "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "0 auto",
    overflow: "hidden",
    "@media only screen and (min-width: 590px)": {
      paddingTop: 0,
    },
    "@media only screen and (min-width: 890px)": {
      "flex-direction": "row",
    },
    "@media only screen and (min-width: 1200px)": {
      "flex-direction": "row",
    },
  },
  imageWrap: {
    display: "flex",
    "flex-direction": "column",
    width: "100%",
    height: "350px",
    position: "relative" as "relative",
    "&.hasLink": {
      padding: "0 80px 0 0",
      "@media only screen and (max-width: 590px)": {
        padding: "0",
      },
    },
    "@media only screen and (min-width: 590px)": {
      width: "100%",
      height: "450px",
    },
    "@media only screen and (min-width: 890px)": {
      width: "80%",
      height: "500px",
    },
    "@media only screen and (min-width: 1200px)": {
      width: "50%",
      height: "600px",
    },
    ".gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
      position: "relative" as "relative",
    },
  },
  content: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    margin: "0 80px",
    /* padding: "20px 80px", */
    alignItems: "flex-start",
    "@media only screen and (min-width: 1200px)": {
      width: "50%",
    },
    "@media only screen and (min-width: 890px)": {
      width: "40%",
    },
    "@media only screen and (max-width: 590px)": {
      width: "100%",
      margin: "0",
      padding: "20px 20px",
    },
    ".gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
      position: "relative" as "relative",
    },
    ".title": {
      display: "flex",
      "flex-direction": "column",
      alignItems: "flex-start",
      width: "100%",
      marginTop: "5px",
      h3: {
        width: "80%",
        fontSize: ["32px", "36px", "44px"],
        fontWeight: 600,
        color: "cgTeal",
        margin: "0 auto",
        letterSpacing: "0.5px",
        lineHeight: "1.35em",
        "text-align": ["center", "center", "left"],
        "&.left": {
          width: "100%",
          marginTop: "20px",
        },
        "&.white": {
          color: "white",
        },
        "&.cgTeal": {
          color: "cgTeal",
        },
      },
      "@media only screen and (min-width: 1200px)": {
        overflow: "hidden",
        marginBottom: "10px",
        "text-overflow": "ellipsis",
      },
    },
    ".button-link-diff": {
      display: "flex",
      width: "80%",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
      color: "cgTeal",
      padding: "10px 0",
      margin: "10px auto",
      cursor: "pointer",
      transition: "all 0.2s ease",
      fontSize: ["14px", "16px", "18px"],
      fontWeight: 600,
      lineHeight: "22px",
      letterSpacing: "0.8px",
      textDecoration: "none",
      "text-align": "center",
      "text-transform": "uppercase",
      "&.left": {
        width: "100%",
      },
      "&.white": {
        color: "white",
      },
      "&.cgTeal": {
        color: "cgTeal",
      },
      p: {
        color: "black !important",
        "&:hover": {
          color: "white !important",
        },
      },
      "&.marginLeft": {
        ml: "60px",
        "@media only screen and (min-width: 1600px)": {
          ml: "80px",
        },
        "@media only screen and (max-width: 590px)": {
          ml: "40px",
        },
      },
      "@media only screen and (max-width: 1200px)": {
        padding: "16px 0",
      },
      "@media only screen and (max-width: 590px)": {
        padding: "10px 0",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    ".description-button": {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      p: {
        width: "100%",
        fontSize: ["16px", "18px", "20px"],
        fontWeight: 500,
        letterSpacing: "0.5px",
        lineHeight: "1.5em",
      },
      "&.white": {
        color: "white",
      },
      "&.cgTeal": {
        color: "cgTeal",
      },
      "&.left": {
        mx: "0",
      },
      "&.margin": {
        mx: "auto",
      },
      "&.marginLeft": {
        ml: "60px",
        "@media only screen and (min-width: 1600px)": {
          ml: "80px",
        },
        "@media only screen and (max-width: 590px)": {
          ml: "10px",
        },
      },
      "@media only screen and (max-width: 1400px)": {
        margin: "10px 0",
      },
      "@media only screen and (max-width: 1200px)": {
        margin: "15px 0",
      },
      "@media only screen and (max-width: 890px)": {
        margin: "15px 0",
      },
      "@media only screen and (max-width: 590px)": {
        margin: "10px 0",
        "text-align": "center",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        "text-overflow": "ellipsis",
      },
    },
  },
  overlayContent: {
    display: "flex",
    width: "100%",
    padding: "0 50px",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "20",
    transition: "all 0.4s ease",
    ".button-link": {
      display: "flex",
      minWidth: "200px",
      color: "black",
      padding: "20px 10px",
      backgroundColor: "white",
      border: "4px solid cgGold",
      margin: "40px auto",
      /* borderRadius: "3px", */
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      lineHeight: "22px",
      letterSpacing: "0.8px",
      transition: "all 0.2s ease",
      fontWeight: 500,
      fontSize: "16px",
      textDecoration: "none",
      "&:hover": {
        backgroundColor: "cgFushia",
        color: "white !important",
      },
      p: {
        color: "black !important",
        "&:hover": {
          color: "white !important",
        },
      },
      "@media only screen and (min-width: 1200px)": {
        fontSize: "20px",
        padding: "20px 10px",
      },
      "@media only screen and (max-width: 590px)": {
        fontSize: "14px",
        padding: "20px 10px",
        minWidth: "150px",
        margin: "40px 20px",
      },
    },
    ".description": {
      fontSize: "22px",
      fontWeight: "bold",
      width: "45%",
      margin: "40px 40px",
      "text-align": "center",
      "@media only screen and (min-width: 1200px)": {
        maxWidth: "1000px",
        fontSize: "20px",
      },
      "@media only screen and (max-width: 1023px)": {
        width: "95%",
        fontSize: "18px",
      },
      "@media only screen and (max-width: 590px)": {
        width: "85%",
        fontSize: "16px",
        height: "80px",
        overflow: "hidden",
        "text-overflow": "ellipsis",
      },
    },
  },
  contentRow: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "10px",
    "@media only screen and (max-width: 590px)": {
      width: "100%",
      margin: "0",
      padding: "20px 20px",
    },
    ".gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
      position: "relative" as "relative",
    },
    ".title-row": {
      display: "flex",
      "flex-direction": "column",
      alignItems: "flex-start",
      width: "100%",
      marginTop: "5px",
      h3: {
        width: "80%",
        fontSize: ["16px", "18px", "22px"],
        fontWeight: 600,
        color: "cgTeal",
        letterSpacing: "0.5px",
        lineHeight: "1.35em",
        "text-align": ["center", "center", "left"],
        "&.left": {
          width: "100%",
          marginTop: "20px",
        },
      },
      "@media only screen and (min-width: 1200px)": {
        overflow: "hidden",
        marginBottom: "10px",
        "text-overflow": "ellipsis",
      },
    },
    ".button-link-diff": {
      display: "flex",
      width: "30%",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
      marginTop: "5px",
      color: "cgTeal",
      cursor: "pointer",
      transition: "all 0.2s ease",
      fontSize: ["14px", "16px", "18px"],
      fontWeight: 600,
      lineHeight: "22px",
      letterSpacing: "0.8px",
      textDecoration: "none",
      "text-align": "center",
      "text-transform": "uppercase",
      "&.left": {
        width: "100%",
      },
      "&:hover": {
        color: "white !important",
      },
      p: {
        color: "black !important",
      },
    },
  },
};

export default styles;
