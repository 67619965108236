/** @jsx jsx */
import { Box, Text, jsx } from "theme-ui";
import { Link } from "gatsby";
import styles from "./cta-button.style";
import cx from "classnames";

type Props = {
  ctaText?: string;
  ctaButtonLink: any;
  isNormal?: boolean;
  hasBackground?: boolean;
  leftIcon?: any;
  rightIcon?: any;
  hasArrow?: boolean;
};

const CtaButton: React.FC<Props> = ({
  ctaText,
  ctaButtonLink,
  isNormal,
  hasBackground,
  leftIcon,
  rightIcon,
  hasArrow,
}) => {
  return (
    <Box
      sx={isNormal ? styles.container : styles.buttonStyle}
      className={cx({
        "button-bg-violet": isNormal,
        "button-bg-not": hasBackground,
      })}
    >
      {ctaButtonLink?.link_type === "Web" ? (
        <a href={ctaButtonLink.url} target="_blank">
          <Text as="span" sx={styles.innerButtonStyle}>
            {ctaText}
          </Text>
        </a>
      ) : ctaButtonLink?.link_type === "Document" ? (
        <Link to={ctaButtonLink.url} sx={styles.divButton}>
          <Text as="span" sx={styles.innerButtonStyle}>
            {ctaText}
          </Text>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="white"
          >
            <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
          </svg>
        </Link>
      ) : (
        <a href="https://fareharbor.com/embeds/book/commongroundkauai/?full-items=yes">
          <Text as="span" sx={styles.innerButtonStyle}>
            {leftIcon}
            {ctaText || "Sign up now"}
            {hasArrow && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="24"
                fill="white"
              >
                <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
              </svg>
            )}
            {rightIcon}
          </Text>
        </a>
      )}
    </Box>
  );
};

export default CtaButton;
