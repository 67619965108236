import styled from "@emotion/styled";
interface Props {
  open: boolean;
}

export const StyledModal = styled.div<Props>`
  position: fixed;
  top: 143px;
  bottom: 0;
  width: 100%;
  color: #535f61;
  height: calc(100% - 143px);
  background-color: rgba(150, 150, 150, 0.3);
  transition: all 0.2s ease;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  z-index: 1000;
  overflow-y: hidden;
  @media (max-width: 890px) {
    top: 173px;
    height: calc(100% - 193px);
  }
  @media (max-width: 768px) {
    top: 143px;
    height: calc(100% - 143px);
  }
  @media (max-width: 576px) {
    top: 173px;
    height: calc(100% - 165px);
  }
  @media (max-width: 550px) {
    top: 165px;
    height: calc(100% - 165px);
  }
  @media (max-width: 448px) {
    top: 113px;
    height: calc(100% - 123px);
  }
  .newsletter {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    background-color: #f69e10;
    width: 80%;
    max-width: 580px;
    position: absolute;
    height: 430px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 40px 35px 40px;
    @media (max-width: 590px) {
      padding: 15px 20px 20px 20px;
      height: auto;
      width: 90%;
    }
    &.image {
      padding: 0;
      height: auto;
    }
  }
  .background {
    position: absolute;
    z-index: -10;
    width: 90%;
    max-width: 310px;
    height: auto;
    top: -5px;
    left: -60px;
    .gatsby-image-wrapper {
      height: 100%;
    }
    @media (max-width: 590px) {
      max-width: 180px;
    }
  }
  .input {
    font-family: Montserrat;
    font-size: 0.9rem;
    padding: 10px;
    color: #054752;
    height: 20px;
    :focus-visible {
      outline: none;
    }
  }
  .button {
    position: absolute;
    right: 15px;
    top: 10px;
    border: none;
    color: #054752;
    height: 50px;
    width: 50px;
    z-index: 200;
    svg {
      height: 30px;
      width: 30px;
      z-index: 0;
    }
    @media (max-width: 590px) {
      right: 0;
      top: 0;
    }
    &.image {
      color: white;
    }
  }
`;
